import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import Logo from '../../assets/logo.svg';
import {Box} from '@material-ui/core';
import './style.scss';

function Header({className}) {

    return (
        <Box className={className}>
            <Link to="/">
                <img src={Logo} alt="logo" className="pointer" />
            </Link>
        </Box>
    );
}

export default Header;

Header.propTypes = {
    className: PropTypes.string
};